'use client';

import { ElementType } from 'react';
import { motion } from 'framer-motion';
import { CustomMotionProps } from './Motion.type';

export const Motion = <Tag extends keyof JSX.IntrinsicElements>({
  type,
  children,
  className,
  ...props
}: CustomMotionProps<Tag>) => {
  const Component = type
    ? (motion[type as keyof typeof motion] as ElementType)
    : motion.div;

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
};
