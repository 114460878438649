import clsx from 'clsx';
import s from './Typography.module.scss';
import { TypographyProps } from './Typography.type';

export const Typography = ({
  children,
  as,
  styling,
  color,
  className,
}: TypographyProps) => {
  const TypeElement = as;
  const elementStyle = styling ?? as;

  return (
    <TypeElement className={clsx(className, elementStyle, color, s.container)}>
      {children}
    </TypeElement>
  );
};
