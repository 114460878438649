export const formatTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = hours;
  const formattedMinutes = remainingMinutes;

  return {
    formattedHours,
    formattedMinutes,
  };
};
