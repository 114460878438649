import clsx from 'clsx';
import s from './InputText.module.scss';
import { InputTextProps } from './InputText.type';

export const InputText = ({
  value,
  onChange,
  placeholder,
  isError,
  className,
  isSuccess,
}: InputTextProps) => {
  return (
    <input
      type="text"
      className={clsx(
        s.input,
        isError && s.error,
        className,
        isSuccess && s.success,
      )}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      autoComplete="email"
    />
  );
};
