'use client';

import { motion } from 'framer-motion';
import { WordPullUpProps } from '~organisms/BentoGrid/components/WordPullUp/WordPullUp.type';
import s from './WordPullUp.module.scss';

export default function WordPullUp({
  words,
  wrapperFramerProps = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.8,
      },
    },
  },
  framerProps = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  },
  className,
  classNameWrapper,
}: WordPullUpProps) {
  return (
    <motion.p
      variants={wrapperFramerProps}
      className={classNameWrapper}
      initial="hidden"
      animate="show"
    >
      {words.split(' ').map((word, i) => (
        <motion.span
          className={className}
          key={i}
          variants={framerProps}
          style={{ display: 'inline-block', paddingRight: '8px' }}
        >
          {word === '' ? <span>&nbsp;</span> : word}
        </motion.span>
      ))}
    </motion.p>
  );
}
