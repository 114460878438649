import { TranslationsKeys } from '~i18n/i18n.type';

// TODO: to change
export const SECTION_IDS = {
  hero: 'hero',
  product: 'product',
  integrations: 'integrations',
  pricing: 'pricing',
} as const;

export const NAVBAR_LINKS: {
  href: keyof typeof SECTION_IDS;
  text: TranslationsKeys;
}[] = [
  {
    href: SECTION_IDS.product,
    text: 'navbar.product',
  },
  {
    href: SECTION_IDS.integrations,
    text: 'navbar.integrations',
  },
  {
    href: SECTION_IDS.pricing,
    text: 'navbar.pricing',
  },
];
