import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { Icon } from '~atoms/Icon/Icon';
import { Typography } from '~atoms/Typography/Typography';
import { useOutsideClick } from '~i18n/hooks/useClickOutside';
import { USERS } from '~organisms/Product/components/InteractiveDemo/InteractiveDemo.const';
import { TaskProps } from '~organisms/Product/components/Task/Task.type';
import s from './Task.module.scss';

export const Task = ({
  data,
  handleDevMaxChange,
  handleDevMinChange,
  handlePersonChange,
}: TaskProps) => {
  const { id, max, min, userId } = data;

  const [title, setTitle] = useState(data.title);
  const [isDevMinOpen, setIsDevMinOpen] = useState(false);
  const [isDevMaxOpen, setIsDevMaxOpen] = useState(false);
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);

  const ref = useRef<HTMLInputElement>(null);
  const devMinRef = useOutsideClick(() => {
    setIsDevMinOpen(false);
  });
  const devMaxRef = useOutsideClick(() => {
    setIsDevMaxOpen(false);
  });
  const assigneeRef = useOutsideClick(() => {
    setIsAssigneeOpen(false);
  });

  const formatTimeValue = (value: number[]) => {
    const [hours, minutes] = value;

    if (hours === 0 && minutes === 0) return '0h 0min';

    return `${String(hours)}h ${String(minutes)}min`;
  };

  return (
    <div className={s.task} key={id}>
      <button
        className={s.taskName}
        onClick={(e) => {
          e.stopPropagation();
          if (!ref.current) return;
          ref.current.focus();
          ref.current.selectionStart = ref.current.value.length;
        }}
      >
        <div className={s.taskNameContent}>
          <input
            type="text"
            ref={ref}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              setTitle(e.target.value);
              e.target.scrollLeft = 0;
            }}
            placeholder=""
            className={clsx('b4', 'gray1300', s.input)}
            value={title}
          />
        </div>
      </button>

      <div ref={devMinRef} className={s.timeWrapper}>
        <button
          className={s.time}
          onClick={() => {
            setIsDevMinOpen((prev) => !prev);
          }}
        >
          <Typography as="span" styling="b4" color="gray1100">
            {formatTimeValue(min)}
          </Typography>

          <div className={s.iconWrapper}>
            <Icon color={'gray700'} name="pencil" size={12} />
          </div>
        </button>

        {isDevMinOpen && (
          <div className={s.dropdown}>
            <div className={s.optionsWrapper}>
              <ul>
                {Array.from({ length: 100 }, (_, i) => i).map((i) => (
                  <li key={i}>
                    <button
                      data-isselected={i === min[0]}
                      onClick={() => {
                        handleDevMinChange(i, id, true);
                      }}
                    >
                      <Typography
                        as="span"
                        styling="b4"
                        color={i === min[0] ? 'background' : 'gray1100'}
                      >
                        {i}
                      </Typography>
                    </button>
                  </li>
                ))}
              </ul>

              <div className={s.divider}></div>

              <ul>
                {[0, 15, 30, 45].map((i) => {
                  return (
                    <li key={i}>
                      <button
                        data-isselected={i === min[1]}
                        onClick={() => {
                          handleDevMinChange(i, id, false);
                        }}
                      >
                        <Typography
                          as="span"
                          styling="b4"
                          color={i === min[1] ? 'background' : 'gray1100'}
                        >
                          {i}
                        </Typography>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className={s.footer}>
              <div className={s.okBtn}>
                <button
                  onClick={() => {
                    setIsDevMinOpen(false);
                  }}
                >
                  <Typography as="span" styling="b5" color="background">
                    OK
                  </Typography>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div ref={devMaxRef} className={s.timeWrapper}>
        <button
          className={s.time}
          onClick={() => {
            setIsDevMaxOpen((prev) => !prev);
          }}
        >
          <Typography as="span" styling="b4" color="gray1300">
            {formatTimeValue(max)}
          </Typography>

          <div className={s.iconWrapper}>
            <Icon color="gray700" name="pencil" size={12} />
          </div>
        </button>

        {isDevMaxOpen && (
          <div className={s.dropdown}>
            <div className={s.optionsWrapper}>
              <ul>
                {Array.from({ length: 100 }, (_, i) => i).map((i) => (
                  <li key={i}>
                    <button
                      data-isselected={i === max[0]}
                      onClick={() => {
                        handleDevMaxChange(i, id, true);
                      }}
                    >
                      <Typography
                        as="span"
                        styling="b4"
                        color={i === max[0] ? 'background' : 'gray1100'}
                      >
                        {i}
                      </Typography>
                    </button>
                  </li>
                ))}
              </ul>

              <div className={s.divider}></div>

              <ul>
                {[0, 15, 30, 45].map((i) => {
                  return (
                    <li key={i}>
                      <button
                        data-isselected={i === max[1]}
                        onClick={() => {
                          handleDevMaxChange(i, id, false);
                        }}
                      >
                        <Typography
                          as="span"
                          styling="b4"
                          color={i === max[1] ? 'background' : 'gray1100'}
                        >
                          {i}
                        </Typography>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className={s.footer}>
              <div className={s.okBtn}>
                <button
                  onClick={() => {
                    setIsDevMaxOpen(false);
                  }}
                >
                  <Typography as="span" styling="b5" color="background">
                    OK
                  </Typography>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={s.assigne} ref={assigneeRef}>
        <button
          onClick={() => {
            setIsAssigneeOpen(true);
          }}
        >
          <div
            style={{ backgroundColor: USERS[userId - 1].color }}
            className={s.avatar}
          >
            <Typography
              as="span"
              styling="b5"
              color="background"
              className={s.letters}
            >
              {USERS[userId - 1].fullname.split(' ')[0][0]}
              {USERS[userId - 1].fullname.split(' ')[1][0]}
            </Typography>
          </div>

          <Typography
            as="span"
            styling="b4"
            color="gray1300"
            className={s.fullname}
          >
            {USERS[userId - 1].fullname}
          </Typography>

          <div className={s.iconWrapper}>
            <Icon color={'gray700'} name="pencil" size={12} />
          </div>
        </button>

        {isAssigneeOpen && (
          <div className={clsx(s.dropdown, s.peopleDropdown)}>
            <div className={s.optionsWrapper}>
              <ul>
                {USERS.map((i) => (
                  <li key={i.id}>
                    <button
                      onClick={() => {
                        handlePersonChange(i.id, id);
                        setIsAssigneeOpen(false);
                      }}
                    >
                      <div
                        style={{ backgroundColor: USERS[i.id - 1].color }}
                        className={s.avatar}
                      >
                        <Typography
                          as="span"
                          styling="b5"
                          color="background"
                          className={s.letters}
                        >
                          {USERS[i.id - 1].fullname.split(' ')[0][0]}
                          {USERS[i.id - 1].fullname.split(' ')[1][0]}
                        </Typography>
                      </div>

                      <Typography
                        as="span"
                        styling="b4"
                        color="gray1300"
                        className={s.fullname}
                      >
                        {USERS[i.id - 1].fullname}
                      </Typography>

                      {i.id === userId && (
                        <Icon
                          className={s.check}
                          name="check"
                          color={'primary'}
                          size={20}
                        />
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
