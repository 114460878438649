import { getTranslation } from '~i18n/hooks/useTranslation';

export const BENTO_GRID_TASKS = [
  {
    title: getTranslation('bentoGrid.tasks.2fa.title'),
    fullname: getTranslation('bentoGrid.tasks.2fa.fullname'),
    time: getTranslation('bentoGrid.tasks.2fa.time'),
    position: getTranslation('bentoGrid.tasks.2fa.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.api_integration.title'),
    fullname: getTranslation('bentoGrid.tasks.api_integration.fullname'),
    time: getTranslation('bentoGrid.tasks.api_integration.time'),
    position: getTranslation('bentoGrid.tasks.api_integration.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.ui_update.title'),
    fullname: getTranslation('bentoGrid.tasks.ui_update.fullname'),
    time: getTranslation('bentoGrid.tasks.ui_update.time'),
    position: getTranslation('bentoGrid.tasks.ui_update.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.deployment_pipeline.title'),
    fullname: getTranslation('bentoGrid.tasks.deployment_pipeline.fullname'),
    time: getTranslation('bentoGrid.tasks.deployment_pipeline.time'),
    position: getTranslation('bentoGrid.tasks.deployment_pipeline.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.frontend_optimization.title'),
    fullname: getTranslation('bentoGrid.tasks.frontend_optimization.fullname'),
    time: getTranslation('bentoGrid.tasks.frontend_optimization.time'),
    position: getTranslation('bentoGrid.tasks.frontend_optimization.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.unit_testing.title'),
    fullname: getTranslation('bentoGrid.tasks.unit_testing.fullname'),
    time: getTranslation('bentoGrid.tasks.unit_testing.time'),
    position: getTranslation('bentoGrid.tasks.unit_testing.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.database_schema.title'),
    fullname: getTranslation('bentoGrid.tasks.database_schema.fullname'),
    time: getTranslation('bentoGrid.tasks.database_schema.time'),
    position: getTranslation('bentoGrid.tasks.database_schema.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.responsive_design.title'),
    fullname: getTranslation('bentoGrid.tasks.responsive_design.fullname'),
    time: getTranslation('bentoGrid.tasks.responsive_design.time'),
    position: getTranslation('bentoGrid.tasks.responsive_design.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.load_testing.title'),
    fullname: getTranslation('bentoGrid.tasks.load_testing.fullname'),
    time: getTranslation('bentoGrid.tasks.load_testing.time'),
    position: getTranslation('bentoGrid.tasks.load_testing.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.server_setup.title'),
    fullname: getTranslation('bentoGrid.tasks.server_setup.fullname'),
    time: getTranslation('bentoGrid.tasks.server_setup.time'),
    position: getTranslation('bentoGrid.tasks.server_setup.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.login_flow.title'),
    fullname: getTranslation('bentoGrid.tasks.login_flow.fullname'),
    time: getTranslation('bentoGrid.tasks.login_flow.time'),
    position: getTranslation('bentoGrid.tasks.login_flow.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.caching_strategy.title'),
    fullname: getTranslation('bentoGrid.tasks.caching_strategy.fullname'),
    time: getTranslation('bentoGrid.tasks.caching_strategy.time'),
    position: getTranslation('bentoGrid.tasks.caching_strategy.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.frontend_debugging.title'),
    fullname: getTranslation('bentoGrid.tasks.frontend_debugging.fullname'),
    time: getTranslation('bentoGrid.tasks.frontend_debugging.time'),
    position: getTranslation('bentoGrid.tasks.frontend_debugging.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.security_audit.title'),
    fullname: getTranslation('bentoGrid.tasks.security_audit.fullname'),
    time: getTranslation('bentoGrid.tasks.security_audit.time'),
    position: getTranslation('bentoGrid.tasks.security_audit.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.cross_browser_testing.title'),
    fullname: getTranslation('bentoGrid.tasks.cross_browser_testing.fullname'),
    time: getTranslation('bentoGrid.tasks.cross_browser_testing.time'),
    position: getTranslation('bentoGrid.tasks.cross_browser_testing.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.microservices_architecture.title'),
    fullname: getTranslation(
      'bentoGrid.tasks.microservices_architecture.fullname',
    ),
    time: getTranslation('bentoGrid.tasks.microservices_architecture.time'),
    position: getTranslation(
      'bentoGrid.tasks.microservices_architecture.position',
    ),
  },
  {
    title: getTranslation('bentoGrid.tasks.feature_development.title'),
    fullname: getTranslation('bentoGrid.tasks.feature_development.fullname'),
    time: getTranslation('bentoGrid.tasks.feature_development.time'),
    position: getTranslation('bentoGrid.tasks.feature_development.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.ui_prototyping.title'),
    fullname: getTranslation('bentoGrid.tasks.ui_prototyping.fullname'),
    time: getTranslation('bentoGrid.tasks.ui_prototyping.time'),
    position: getTranslation('bentoGrid.tasks.ui_prototyping.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.load_balancer.title'),
    fullname: getTranslation('bentoGrid.tasks.load_balancer.fullname'),
    time: getTranslation('bentoGrid.tasks.load_balancer.time'),
    position: getTranslation('bentoGrid.tasks.load_balancer.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.api_testing.title'),
    fullname: getTranslation('bentoGrid.tasks.api_testing.fullname'),
    time: getTranslation('bentoGrid.tasks.api_testing.time'),
    position: getTranslation('bentoGrid.tasks.api_testing.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.code_review.title'),
    fullname: getTranslation('bentoGrid.tasks.code_review.fullname'),
    time: getTranslation('bentoGrid.tasks.code_review.time'),
    position: getTranslation('bentoGrid.tasks.code_review.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.ui_theming.title'),
    fullname: getTranslation('bentoGrid.tasks.ui_theming.fullname'),
    time: getTranslation('bentoGrid.tasks.ui_theming.time'),
    position: getTranslation('bentoGrid.tasks.ui_theming.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.server_monitoring.title'),
    fullname: getTranslation('bentoGrid.tasks.server_monitoring.fullname'),
    time: getTranslation('bentoGrid.tasks.server_monitoring.time'),
    position: getTranslation('bentoGrid.tasks.server_monitoring.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.responsive_grid.title'),
    fullname: getTranslation('bentoGrid.tasks.responsive_grid.fullname'),
    time: getTranslation('bentoGrid.tasks.responsive_grid.time'),
    position: getTranslation('bentoGrid.tasks.responsive_grid.position'),
  },
  {
    title: getTranslation('bentoGrid.tasks.performance_testing.title'),
    fullname: getTranslation('bentoGrid.tasks.performance_testing.fullname'),
    time: getTranslation('bentoGrid.tasks.performance_testing.time'),
    position: getTranslation('bentoGrid.tasks.performance_testing.position'),
  },
];
