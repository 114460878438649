import { getTranslation } from '~i18n/hooks/useTranslation';

export const USERS = [
  {
    fullname: getTranslation('product.user1'),
    id: 1,
    color: '#F97316',
  },
  {
    fullname: getTranslation('product.user2'),
    id: 2,
    color: '#3B82F6',
  },
  {
    fullname: getTranslation('product.user3'),
    id: 3,
    color: '#6366F1',
  },
];

export const DEFAULT_TASKS = [
  {
    id: crypto.randomUUID(),
    role: 'Frontend',
    items: [
      {
        id: crypto.randomUUID(),
        title: getTranslation('product.task1'),
        min: [0, 0],
        max: [0, 0],
        userId: 1,
      },
      {
        id: crypto.randomUUID(),
        title: getTranslation('product.task2'),
        min: [5, 0],
        max: [7, 45],
        userId: 2,
      },
      {
        id: crypto.randomUUID(),
        title: getTranslation('product.task3'),
        min: [5, 0],
        max: [7, 45],
        userId: 2,
      },
      {
        id: crypto.randomUUID(),
        title: getTranslation('product.task4'),
        min: [8, 0],
        max: [10, 30],
        userId: 1,
      },
    ],
  },
  {
    role: 'Backend',
    id: crypto.randomUUID(),

    items: [
      {
        id: crypto.randomUUID(),
        title: getTranslation('product.task5'),
        min: [1, 30],
        max: [3, 0],
        userId: 3,
      },
      {
        id: crypto.randomUUID(),
        title: getTranslation('product.task6'),
        min: [3, 0],
        max: [4, 0],
        userId: 3,
      },
      {
        id: crypto.randomUUID(),
        title: getTranslation('product.task7'),
        min: [3, 30],
        max: [5, 0],
        userId: 3,
      },
    ],
  },
];
