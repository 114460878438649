'use client';

import Lottie from 'lottie-react';
import integrationsAnimation from '~assets/video/integrationsAnimation.json';
import s from './IntegrationsVideoAnimation.module.scss';

export const IntegrationsVideoAnimation = () => {
  return (
    <Lottie
      animationData={integrationsAnimation}
      className={s.video}
      loop
      autoPlay
    />
  );
};
