'use client';

import { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useMotionValue, useSpring, useInView } from 'framer-motion';
import { Icon } from '~atoms/Icon/Icon';
import { Motion } from '~atoms/Motion/Motion';
import { Typography } from '~atoms/Typography/Typography';
import { useTranslation } from '~i18n/hooks/useTranslation';
import { Marquee } from '~molecules/Marquee/Marquee';
import { BENTO_GRID_TASKS } from '~organisms/BentoGrid/BentoGrid.const';
import { formatTime } from '~organisms/BentoGrid/BentoGrid.utils';
import { TextGenerateEffect } from '~organisms/BentoGrid/components/TextGenerateEffect/TextGenerateEffect';
import WordPullUp from '~organisms/BentoGrid/components/WordPullUp/WordPullUp';
import { textVariant } from '~utils/motion';
import s from './BentoGrid.module.scss';

export const BentoGrid = () => {
  const { t } = useTranslation();

  const itemsFirstHalf = BENTO_GRID_TASKS.slice(
    0,
    Math.floor(BENTO_GRID_TASKS.length / 2),
  );
  const itemsSecondHalf = BENTO_GRID_TASKS.slice(
    Math.floor(BENTO_GRID_TASKS.length / 2),
    BENTO_GRID_TASKS.length,
  );

  const options = {
    damping: 60,
    stiffness: 100,
  };
  const [minutes, setMinutes] = useState(750);
  const value = 60;
  const hoursRef = useRef<HTMLSpanElement>(null);
  const minutesRef = useRef<HTMLSpanElement>(null);
  const upToHoursRef = useRef<HTMLSpanElement>(null);
  const upToHoursValue = useMotionValue(0);
  const hoursValue = useMotionValue(0);
  const minutesValue = useMotionValue(0);
  const upToHoursSpringValue = useSpring(upToHoursValue, options);
  const hoursSpringValue = useSpring(hoursValue, options);
  const minutesSpringValue = useSpring(minutesValue, options);
  const isInView = useInView(upToHoursRef, { once: true, margin: '0px' });

  useEffect(() => {
    isInView &&
      setTimeout(() => {
        upToHoursValue.set(value);

        const { formattedHours, formattedMinutes } = formatTime(minutes);

        hoursValue.set(formattedHours);
        minutesValue.set(formattedMinutes);
      }, 400);
  }, [upToHoursValue, isInView, value, hoursValue, minutesValue, minutes]);

  useEffect(() => {
    upToHoursSpringValue.on('change', (latest) => {
      if (upToHoursRef.current) {
        upToHoursRef.current.textContent = Intl.NumberFormat('en-US').format(
          Number(latest.toFixed(0)),
        );
      }
    });

    hoursSpringValue.on('change', (latest) => {
      if (hoursRef.current) {
        hoursRef.current.textContent = Intl.NumberFormat('en-US').format(
          Number(latest.toFixed(0)),
        );
      }
    });

    minutesSpringValue.on('change', (latest) => {
      if (minutesRef.current) {
        minutesRef.current.textContent = Intl.NumberFormat('en-US').format(
          Number(latest.toFixed(0)),
        );
      }
    });
  }, [
    upToHoursSpringValue,
    hoursSpringValue,
    minutesSpringValue,
    hoursValue,
    minutesValue,
  ]);

  useEffect(() => {
    if (!isInView) return;

    const generateRandomNumber = () => {
      const number = Math.floor(Math.random() * (221 - 10 + 1)) + 10;
      setMinutes((prevValue) => prevValue + number);
    };

    const getRandomInterval = () => {
      return Math.random() * (12000 - 3000) + 3000;
    };

    const intervalId = setInterval(generateRandomNumber, getRandomInterval());

    return () => clearInterval(intervalId);
  }, [minutes, isInView]);

  return (
    <Motion
      type="section"
      variants={textVariant(0.2)}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={s.root}
    >
      <div className={s.row}>
        <div className={clsx(s.itemSmall, s.upTo)}>
          <Typography as="span" color="gray800">
            {t('bentoGrid.upTo')}
          </Typography>

          <span ref={upToHoursRef} className={s.hours} />

          <Typography as="span" className={s.hoursSaved} color="background">
            {t('bentoGrid.hoursSaved')}
          </Typography>
        </div>

        <div className={s.itemLarge}>
          <div className={s.marquee}>
            <div className={s.rightGradient} />
            <div className={s.leftGradient} />
            <Marquee pauseOnHover>
              {itemsFirstHalf.map(({ fullname, position, time, title }) => {
                const positionClass = position.replace('/', '');

                return (
                  <div key={title} className={s.task}>
                    <Typography as="p" styling="b3" color="gray100">
                      {title}
                    </Typography>

                    <div className={s.metadata}>
                      <Typography as="p" styling="b4" color="gray600">
                        {time}
                      </Typography>
                      <div className={s.dot} />
                      <Typography as="p" styling="b4" color="gray600">
                        {fullname}
                      </Typography>
                      <div className={s.dot} />
                      <div>
                        <Typography
                          // @ts-ignore
                          // Adding ts ignore cuz i cant :(
                          className={clsx(s[positionClass], s.badge)}
                          as="p"
                          styling="b5"
                        >
                          {position}
                        </Typography>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Marquee>
            <Marquee reverse pauseOnHover>
              {itemsSecondHalf.map(({ fullname, position, time, title }) => {
                const positionClass = position.replace('/', '');

                return (
                  <div key={title} className={s.task}>
                    <Typography as="p" styling="b3" color="gray100">
                      {title}
                    </Typography>

                    <div className={s.metadata}>
                      <Typography as="p" styling="b4" color="gray600">
                        {time}
                      </Typography>
                      <div className={s.dot} />
                      <Typography as="p" styling="b4" color="gray600">
                        {fullname}
                      </Typography>
                      <div className={s.dot} />
                      <div>
                        <Typography
                          // @ts-ignore
                          // Adding ts ignore cuz i cant :(
                          className={clsx(s[positionClass], s.badge)}
                          as="p"
                          styling="b5"
                        >
                          {position}
                        </Typography>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Marquee>
          </div>

          <div className={s.texts}>
            <Typography as="p" styling="b2" color="background">
              {t('bentoGrid.roleBasedTitle')}
            </Typography>
            <Typography as="p" styling="pSmall" color="gray500">
              {t('bentoGrid.roleBasedDescription')}
            </Typography>
          </div>
        </div>
      </div>

      <div className={s.row}>
        <div className={clsx(s.itemSmall, s.instantCalculations)}>
          <Motion type="div" variants={textVariant(0.6, true)}>
            <Typography as="p" color="background" className={s.time}>
              <span ref={hoursRef} />h <span ref={minutesRef} />
              min
            </Typography>
          </Motion>

          <WordPullUp
            classNameWrapper={s.instantCalculationsTextContainer}
            className={s.instantCalculationsText}
            words={t('bentoGrid.instantEstimates')}
          />
        </div>

        <div className={clsx(s.itemLarge, s.scalableContainer)}>
          <Icon
            size={230}
            strokeWidth={1}
            className={s.icon}
            name="blocks"
            color="primary"
          />

          <TextGenerateEffect
            className={s.scalable}
            words={t('bentoGrid.scalable')}
          />
        </div>
      </div>
    </Motion>
  );
};
