import clsx from 'clsx';
import { DEFAULT_ICON_SIZE, DEFAULT_ICON_STROKE_WIDTH } from './Icon.const';
import { ICONS_MAP } from './Icon.map';
import { IconProps } from './Icon.type';

export const Icon = ({
  name,
  color,
  fill,
  size,
  strokeWidth,
  className,
}: IconProps) => {
  const Icon = ICONS_MAP[name];

  return (
    <Icon
      fill={fill ? `var(--${fill})` : 'transparent'}
      size={size ?? DEFAULT_ICON_SIZE}
      strokeWidth={strokeWidth ?? DEFAULT_ICON_STROKE_WIDTH}
      className={clsx(color, className)}
    />
  );
};
