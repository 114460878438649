'use client';

import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import s from './WordRotate.module.scss';
import { WordRotateProps } from './WordRotate.type';

export const WordRotate = ({
  words,
  duration = 3000,
  className,
}: WordRotateProps) => {
  const [index, setIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const textRef = useRef<HTMLHeadingElement | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, duration);

    return () => clearInterval(interval);
  }, [words, duration]);

  useEffect(() => {
    if (!textRef.current) {
      return;
    }

    setWidth(textRef.current.offsetWidth ?? 0);
  }, [index]);

  return (
    <motion.div
      layout
      animate={{
        width,
      }}
      transition={{
        delay: 0.05,
      }}
      className={s.wordRotate}
    >
      <motion.span
        layout
        ref={textRef}
        key={words[index].text}
        className={clsx(className, s.noWrap, words[index].color)}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.25, ease: 'easeOut' }}
      >
        {words[index].text}
      </motion.span>
    </motion.div>
  );
};
