import clsx from 'clsx';
import { Icon } from '~atoms/Icon/Icon';
import { Typography } from '~atoms/Typography/Typography';
import s from './Badge.module.scss';
import { BadgeProps } from './Badge.type';

export const Badge = ({
  type,
  text,
  iconName,
  iconColor,
  iconFill,
  strokeWidth,
  withShinyAnimation,
  isSmall,
}: BadgeProps) => {
  return (
    <div className={clsx(s.badge, s[type])}>
      {withShinyAnimation ? (
        <Typography className={s.shimmer} as="span" styling="badge">
          {text}
        </Typography>
      ) : (
        <Typography as="span" styling={isSmall ? 'badgeSmall' : 'badge'}>
          {text}
        </Typography>
      )}

      {iconName && (
        <Icon
          name={iconName}
          size={16}
          color={iconColor}
          fill={iconFill}
          strokeWidth={strokeWidth}
        />
      )}
    </div>
  );
};
