import Link from 'next/link';
import clsx from 'clsx';
import s from './AppLink.module.scss';
import type { AppLinkProps } from './AppLink.type';

export const AppLink = (props: AppLinkProps) => {
  const { children, color, className, ...linkProps } = props;

  return (
    <Link
      className={clsx(s.link, className, color ? color : s.colorInherit)}
      {...linkProps}
      color={color}
    >
      {children}
    </Link>
  );
};
