import clsx from 'clsx';
import s from './Marquee.module.scss';
import { MarqueeProps } from './Marquee.type';

export const Marquee = ({
  className = '',
  reverse = false,
  pauseOnHover = false,
  children,
  vertical = false,
  repeat = 4,
  ...props
}: MarqueeProps) => {
  return (
    <div
      {...props}
      className={clsx(
        s.marqueeContainer,
        vertical ? s.marqueeVertical : s.marqueeHorizontal,
        pauseOnHover && s.marqueePaused,
        reverse && s.marqueeReverse,
      )}
    >
      {Array(repeat)
        .fill(0)
        .map((_, i) => (
          <div key={i} className={s.marqueeContent}>
            {children}
          </div>
        ))}
    </div>
  );
};
