import clsx from 'clsx';
import { Icon } from '~atoms/Icon/Icon';
import { DEFAULT_LOADER_SIZE } from './Loader.const';
import s from './Loader.module.scss';
import { LoaderProps } from './Loader.type';

export const Loader = ({
  isFullScreen = true,
  color = 'gray1400',
  size,
}: LoaderProps) => {
  return (
    <div className={clsx(isFullScreen && s.container)}>
      <Icon
        name="loaderCircle"
        strokeWidth={3}
        size={size ?? DEFAULT_LOADER_SIZE}
        color={color}
        className={s.loader}
      />
    </div>
  );
};
