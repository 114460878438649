'use client';

import { FormEvent, useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button } from '~atoms/Button/Button';
import { InputText } from '~atoms/InputText/InputText';
import { Typography } from '~atoms/Typography/Typography';
import { useTranslation } from '~i18n/hooks/useTranslation';
import s from './WaitlistForm.module.scss';
import { WaitlistFormProps } from './WaitlistForm.type';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

export const WaitlistForm = ({ buttonVariant }: WaitlistFormProps) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState<string | null>(null);

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email || !EMAIL_REGEX.test(email)) {
      setError(t('hero.form.invalidEmail'));
      return;
    }

    try {
      if (!executeRecaptcha) {
        console.error('Captcha error');
        return;
      }

      const token = await executeRecaptcha();

      const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ['g-recaptcha-response']: token,
          email,
        }),
      });

      const finalResponse = await res.json();

      if (finalResponse.success) {
        setSuccess(t('hero.form.success'));

        setTimeout(() => {
          setSuccess(null);
          setEmail('');
        }, 2500);
      }
    } catch {}

    setError('');
  };

  return (
    <div className={s.waitlist}>
      <form className={s.form} onSubmit={handleFormSubmit}>
        <div className={s.input}>
          <InputText
            value={email}
            onChange={(value) => setEmail(value)}
            placeholder={t('hero.form.email')}
            isError={!!error}
            isSuccess={success !== null}
            className={s.input}
          />
          <Typography
            as="span"
            styling="helperText"
            color={success !== null ? 'success' : 'danger'}
            className={s.error}
          >
            {success ? success : error}
          </Typography>
        </div>

        <Button
          text={t('hero.form.submit')}
          type="submit"
          className={s.button}
          variant={buttonVariant}
        />
      </form>
    </div>
  );
};
