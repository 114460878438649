'use client';

import { useState } from 'react';
import { Inter } from 'next/font/google';
import clsx from 'clsx';
import { Badge } from '~atoms/Badge/Badge';
import { Motion } from '~atoms/Motion/Motion';
import { Typography } from '~atoms/Typography/Typography';
import { SECTION_IDS } from '~constants/navbar';
import { useTranslation } from '~i18n/hooks/useTranslation';
import { DEFAULT_TASKS } from '~organisms/Product/components/InteractiveDemo/InteractiveDemo.const';
import { Task } from '~organisms/Product/components/Task/Task';
import { textVariant } from '~utils/motion';
import s from './InteractiveDemo.module.scss';

const inter = Inter({ subsets: ['latin'] });

export const InteractiveDemo = () => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState(DEFAULT_TASKS);
  const [moduleName, setModuleName] = useState('Payment module');

  const handleDevMinChange = (
    newVal: number,
    taskId: string,
    isHour: boolean,
  ) => {
    setTasks((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          items: item.items.map((task) => {
            if (task.id !== taskId) return task;
            return {
              ...task,
              min: isHour ? [newVal, task.min[1]] : [task.min[0], newVal],
            };
          }),
        };
      });
    });
  };

  const handleDevMaxChange = (
    newVal: number,
    taskId: string,
    isHour: boolean,
  ) => {
    setTasks((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          items: item.items.map((task) => {
            if (task.id !== taskId) return task;
            return {
              ...task,
              max: isHour ? [newVal, task.max[1]] : [task.max[0], newVal],
            };
          }),
        };
      });
    });
  };

  const handlePersonChange = (newVal: number, taskId: string) => {
    setTasks((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          items: item.items.map((task) => {
            if (task.id !== taskId) return task;
            return {
              ...task,
              userId: newVal,
            };
          }),
        };
      });
    });
  };

  const sumHours = (max: boolean) => {
    const hours = tasks.reduce((acc, item) => {
      return (
        acc +
        item.items.reduce((acc, item) => {
          return acc + item[max ? 'max' : 'min'][0];
        }, 0)
      );
    }, 0);

    const minutes = tasks.reduce((acc, item) => {
      return (
        acc +
        item.items.reduce((acc, item) => {
          return acc + item[max ? 'max' : 'min'][1];
        }, 0)
      );
    }, 0);

    return Math.round(hours + minutes / 60);
  };

  return (
    <Motion
      type="div"
      variants={textVariant(0.8)}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={clsx(s.root, inter.className)}
      id={SECTION_IDS.product}
    >
      <div className={s.moduleHeader}>
        <Typography as="p" styling="b2">
          {moduleName}
        </Typography>

        <div className={s.dot} />

        <div className={s.summaryBadges}>
          <Badge isSmall type="filled" text={`min: ${sumHours(false)}h`} />
          <Badge isSmall type="filled" text={`max: ${sumHours(true)}h`} />
        </div>
      </div>

      <div className={s.roles}>
        {tasks.map(({ role, items, id: moduleId }) => {
          return (
            <div key={moduleId} className={s.roleContainer}>
              <div>
                <Typography as="p" styling="b2">
                  {role}
                </Typography>
              </div>

              <div className={s.tasks}>
                {items.map((item) => (
                  <Task
                    handleDevMinChange={handleDevMinChange}
                    handleDevMaxChange={handleDevMaxChange}
                    handlePersonChange={handlePersonChange}
                    data={item}
                    key={item.id}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>

      <div className={s.noteText}>
        <Typography styling="b5" color="gray600" as="p">
          {t('product.note')}
        </Typography>
      </div>
    </Motion>
  );
};
