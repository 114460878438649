type Direction = 'up' | 'right' | 'down' | 'left' | '';

export const textVariant = (delay?: number, isSmall?: boolean) => {
  if (isSmall) {
    return {
      hidden: {
        y: 20,
        opacity: 0,
      },
      show: {
        y: 0,
        opacity: 1,
        transition: {
          ease: 'easeOut',
          duration: 0.4,
          delay: delay,
        },
      },
    };
  }

  return {
    hidden: {
      y: 65,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: 'easeOut',
        duration: 0.55,
        delay: delay,
      },
    },
  };
};

export const fadeIn = (
  direction: Direction,
  type: string,
  delay: number,
  duration: number,
) => {
  return {
    hidden: {
      x: direction === 'left' ? 100 : direction === 'right' ? -100 : 0,
      y: direction === 'up' ? 100 : direction === 'down' ? -100 : 0,
      opacity: 0,
    },
    show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        type: type,
        delay: delay,
        duration: duration,
        ease: 'easeOut',
      },
    },
  };
};

export const zoomIn = (delay: number, duration: number) => {
  return {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    show: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'tween',
        delay: delay,
        duration: duration,
        ease: 'easeOut',
      },
    },
  };
};

export const slideIn = (
  direction: Direction,
  type: string,
  delay: number,
  duration: number,
) => {
  return {
    hidden: {
      x: direction === 'left' ? '-100%' : direction === 'right' ? '100%' : 0,
      y: direction === 'up' ? '100%' : direction === 'down' ? '100%' : 0,
    },
    show: {
      x: 0,
      y: 0,
      transition: {
        type: type,
        delay: delay,
        duration: duration,
        ease: 'easeOut',
      },
    },
  };
};

export const staggerContainer = (
  staggerChildren?: number,
  delayChildren?: number,
) => {
  return {
    hidden: {},
    show: {
      transition: {
        staggerChildren: staggerChildren,
        delayChildren: delayChildren || 0,
      },
    },
  };
};
